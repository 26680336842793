import { createTheme } from '@mantine/core'

import {
  BLUE_MANTINE_PALETTE,
  GRAY_MANTINE_PALETTE,
  GREEN_MANTINE_PALLETE,
  RED_MANTINE_PALETTE,
  TEAL_BLUE_MANTINE_PALLETE,
  VIOLET_MANTINE_PALLETE,
} from '@constants/styles'

import { ButtonConfig } from '../configs'

export const useCustomTheme = () => {
  return createTheme({
    fontFamily: 'Manrope, sans-serif',
    fontFamilyMonospace: 'Roboto Mono, monospace',
    activeClassName: '',
    cursorType: 'pointer',
    colors: {
      gray: GRAY_MANTINE_PALETTE,
      green: GREEN_MANTINE_PALLETE,
      red: RED_MANTINE_PALETTE,
      teal: TEAL_BLUE_MANTINE_PALLETE,
      violet: VIOLET_MANTINE_PALLETE,
      blue: BLUE_MANTINE_PALETTE,
    },
    primaryShade: 8,
    primaryColor: 'teal',

    components: {
      Button: ButtonConfig,
    },
  })
}
